$(document).ready(() => {
    var newDiv = document.createElement("div");
    newDiv.className = "swishlist-success-message alert-info";
    newDiv.innerHTML = "<p> Product removed from wishlist</p>";
    var headerInfo = document.querySelector("#main");
    var content = headerInfo.querySelector("#content");
    headerInfo.insertBefore(newDiv, content);
    $('.swishlist-success-message').hide();
    $('#swishlist-nb').text(swishlist.nbProducts);

    $('body').on('click', '.js-swishlist-add', function (event) {
        let self = this;
        prestashop.emit('clickSWishlistAdd', {
            dataset: self.dataset,
            self: self
        });
        event.preventDefault();
    });

    $('body').on('click', '.js-swishlist-remove', function (event) {

        let self = this;
        prestashop.emit('clickSWishlistRemove', {
            dataset: self.dataset
        });
        event.preventDefault();
        $('.swishlist-success-message').show();
        if (swishlist.nbProducts == 0) {
            location.reload();
            $('#swishlist-crosseling, #swishlist-share').addClass('hidden-xs-up');
        }
    });


    prestashop.on('clickSWishlistAdd', function (elm) {
        let data = {
            'process': 'add',
            'ajax': 1,
            'idProduct': elm.dataset.idProduct,
            'idProductAttribute': elm.dataset.idProductAttribute,
        };

        $.post(elm.dataset.url, data, null, 'json').then(function (resp) {

            if (!resp.success && resp.data.type == 'notLogged'){
                let quickModal = $('.modal.quickview').first();

                if (quickModal.length){
                    $(quickModal).modal('hide');
                    $(quickModal).on('hidden.bs.modal', function (e) {
                        $('#swishlist-modal').modal('show');
                    });
                } else{
                    $('#swishlist-modal').modal('show');
                }
                return;
            }
            $(elm.self).addClass('swishlist-added');
            if (resp.success){
                swishlist.nbProducts++;
                $('#swishlist-nb').text(swishlist.nbProducts);

                let $notification =  $('#swishlist-notification');
                $notification.addClass('ns-show');

                setTimeout(function(){
                    $notification.removeClass('ns-show');
                }, 3500);
            }

        }).fail((resp) => {
            prestashop.emit('handleError', {eventType: 'clickSWishlistAdd', resp: resp});
        });

    });

    prestashop.on('clickSWishlistRemove', function (elm) {

        let data = {
            'process': 'remove',
            'ajax': 1,
            'idProduct': elm.dataset.idProduct,
        };
        $.post(elm.dataset.url, data, null, 'json').then(function (resp) {
            console.log('#swishlist-product-' + elm.dataset.idProduct);
            let swishlistProductRemove = $('#swishlist-product-' + elm.dataset.idProduct).remove();
            swishlist.nbProducts--;
            $('#swishlist-nb').text(swishlist.nbProducts);
            if(swishlistProductRemove){
                $('.swishlist-success-message').show()
            }
                if (swishlist.nbProducts == 0) {
                    $('#swishlist-warning').removeClass('hidden-xs-up');
                    $('#swishlist-crosseling, #swishlist-share').addClass('hidden-xs-up');
                }
        }).fail((resp) => {
            prestashop.emit('handleError', {eventType: 'clickSWishlistRemove', resp: resp});
        });

    });

    prestashop.on('updatedProduct', function (elm) {
        $('#studio-wishlist-product-btn').data('id-product-attribute', elm.id_product_attribute);
        $('#studio-wishlist-product-btn').attr('data-id-product-attribute', elm.id_product_attribute);
    });


    $('#swishlist-clipboard-btn').on('click', function () {

        var $btn = $(this);

        var copyInput = $btn.closest('.input-group').children('input.js-to-clipboard');
        copyInput.select();

        try {
            var successful = document.execCommand('copy');
            if (successful) {
                $btn.text($btn.data('textCopied'));
                setTimeout(function(){ $btn.text($btn.data('textCopy'));  }, 1500);
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    });


    $('.add-to-cart-all').click(function () {

        let ajax_url = $(this).attr('data-action-url');
        $.ajax({
            url: ajax_url,
            type: 'post',
            dataType: 'json',
            data: {
                action: 'addAllToCart',
            },
            success: function (json) {
                if (json.success === true) {
                    if (typeof json.data.message !== undefined) {
                        $('.action-buttons .cart-info').html(json.data.message);
                        $('.action-buttons .cart-info').removeClass('d-none');

                        location.href = json.data.redirect_url;
                    }
                }
            }
        });
    });
});


